import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { serialize } from 'object-to-formdata';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { API_URLS } from 'src/api/apiUrls';
import useApiServices from 'src/api/useApiServices';
import {
  useAddMatnSectionMutation,
  useAddSoundsMutation,
  useAddWordsDefinitionsMutation,
  useDeleteSoundMutation,
  useDeleteTranslationMutation,
  useMatnSectionBySectionIdQuery,
  useSoundsByMatnSectionIdQuery,
  useTranslationsByMatnSectionIdQuery,
  useUpdateMatnSectionMutation,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { useParams } from 'src/routes/hooks';
import useLangsWithErrors from 'src/routes/hooks/use-langs-with-errors';
import { paths } from 'src/routes/paths';
import useSchemas from 'src/schemas/hooks/useSchemas';
import { AddMatnSectionMutationVariables, NewMatnSectionStateType } from 'src/types/matn-sections';
import { AddNewMatnSectionSoundsMutationType } from 'src/types/sounds';
import { getLocalizedValue } from 'src/utils/get-localized-value';
import { isFilledSound, isFilledWord } from 'src/utils/matn-sections-utils';

const useAddNewMatnSection = () => {
  // #region States
  const router = useNavigate();
  const confirm = useBoolean();
  const { matnSectionId: matnSectionIdPrm, matnId } = useParams();
  const addSecSounds = useBoolean();
  const addDiffWords = useBoolean();
  const confirmDeleteSound = useBoolean();
  const confirmDeleteWord = useBoolean();
  const queryClient = useQueryClient();
  const { t, allLangs, currentLang } = useLocales();
  const { useMultiPostApi } = useApiServices();
  const { enqueueSnackbar } = useSnackbar();
  const { matnSectionSchema } = useSchemas();
  const [currentTabValue, setCurrentTabValue] = useState(allLangs[0].value);
  const [transDeleteId, setTransDeleteId] = useState<string>();
  const [soundDeleteId, setSoundDeleteId] = useState<string>();
  const [dataToSend, setDataToSend] = useState<AddMatnSectionMutationVariables>();
  const { state }: { state: NewMatnSectionStateType | undefined } = useLocation();
  const [matnSectionId, setMatnSectionId] = useState<string | undefined>(
    state?.matnSection?.mutoonSectionId || matnSectionIdPrm
  );
  const isEditing = !!matnSectionIdPrm || !!state?.matnSection;

  const matnName = state?.matn ? getLocalizedValue(state.matn.name) : 'Matn';
  const matnSectionName = state?.matnSection
    ? getLocalizedValue(state.matnSection.name)
    : 'Matn section';
  // #endregion States

  // #region form
  // General form (details + difficult words)
  let defaultValues: AddMatnSectionMutationVariables = {
    name: state?.matnSection?.name || {},
    description: state?.matnSection?.description || {},
    mutoonMatnId: matnId,
    wordsDefinitions: [
      {
        word: {},
        translatedText: {},
        mutoonSectionId: matnSectionIdPrm!,
      },
    ],
  };

  for (let i = 0; i < allLangs?.length && !state; i++) {
    defaultValues.name[allLangs[i].value] = '';
    defaultValues.description[allLangs[i].value] = '';
    defaultValues!.wordsDefinitions![0]!.translatedText![allLangs[i].value] = '';
  }

  const methods = useForm<AddMatnSectionMutationVariables>({
    defaultValues,
    resolver: yupResolver(matnSectionSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddMatnSectionMutationVariables) => {
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );

  const langsWithErrors = useLangsWithErrors(errors);
  // Sounds form
  let soundsDefaultValues: AddNewMatnSectionSoundsMutationType['sounds'][0] = {
    name: {},
    description: {},
    mutoonSectionId: '',
    fileUrl: '',
  };

  for (let i = 0; i < allLangs?.length && !state?.matnSection; i++) {
    soundsDefaultValues.name[allLangs[i].value] = '';
    soundsDefaultValues.description[allLangs[i].value] = '';
  }

  const soundForm = useForm<AddNewMatnSectionSoundsMutationType>({
    defaultValues: { sounds: [soundsDefaultValues] },
  });

  const soundsErrors = useLangsWithErrors(soundForm.formState.errors);

  const newWordsDefs = methods.watch().wordsDefinitions?.filter((word) => !word.translationId);
  const hasNewWordsDefs = methods
    .watch()
    .wordsDefinitions?.filter((word) => !word.translationId)
    .filter((word) => isFilledWord({ word: word.word })).length;

  const newSounds = soundForm.watch().sounds?.filter((sound) => !sound.soundId);
  const hasNewSounds = soundForm
    .watch()
    .sounds.filter((sound) => !sound.soundId)
    .filter((sound) => isFilledSound({ name: sound.name })).length;
  // #endregion form

  // #region Services
  // Get matn section details from database if it's not available in the state
  const { isLoading: isLoadingSectionDetails } = isEditing
    ? useMatnSectionBySectionIdQuery(
        {
          mutoonSectionId: matnSectionId!,
        },
        {
          onSuccess: (data) => {
            methods.setValue('name', data.mutoon_sections_by_pk?.name);
            methods.setValue('description', data.mutoon_sections_by_pk?.description);
          },
        }
      )
    : { isLoading: false };

  // Add matn section
  const {
    mutate: addMatnSection,
    isLoading: isAddingMatnSection,
    isSuccess: hasAddedSection,
  } = useAddMatnSectionMutation({
    onSuccess: (data) => {
      setMatnSectionId(data.insert_mutoon_sections_one?.mutoonSectionId);
      confirm.onFalse();
      router(`${paths.dashboard.contentManagement.mutoon.matn}/${matnId}`);
    },
  });

  // Edit matn section
  const {
    mutate: editMatnSection,
    isLoading: isUpdatingSec,
    isSuccess: hasBeenEdited,
  } = useUpdateMatnSectionMutation({
    onSuccess: () => {
      confirm.onFalse();
    },
  });

  // Choose to add or edit
  const mutateSectionDetails = isEditing
    ? () =>
        editMatnSection({
          name: dataToSend?.name,
          description: dataToSend?.description,
          mutoonSectionId: matnSectionId!,
        })
    : () =>
        addMatnSection({
          name: dataToSend?.name,
          description: dataToSend?.description,
          matnId,
        });

  // Get words definitions by matn section id
  const { isLoading: isLoadingWordsDefs } = isEditing
    ? useTranslationsByMatnSectionIdQuery(
        {
          _eq: matnSectionId,
        },
        {
          onSuccess: (data) => {
            methods.setValue('wordsDefinitions', data.translations);
            if (data.translations.length) {
              addDiffWords.onTrue();
            }
          },
        }
      )
    : { isLoading: false };

  // Add words defeniftions
  const { mutate: addWordsDefinitions, isLoading: isAddingWords } =
    useAddWordsDefinitionsMutation();

  // Delete word definition
  const { mutate: deleteWordDefinition, isLoading: isDeletingWord } = useDeleteTranslationMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['TranslationsByMatnSectionId'] });
      confirmDeleteWord.onFalse();
    },
  });

  // Get sounds by matn section id
  const { isLoading: isLoadingSounds } = isEditing
    ? useSoundsByMatnSectionIdQuery(
        {
          matnSectionId,
        },
        {
          onSuccess: (data) => {
            soundForm.setValue(
              'sounds',
              data.sounds.map((sound) => ({ ...sound, description: sound.description }))
            );
            if (data.sounds?.length) {
              addSecSounds.onTrue();
            }
          },
        }
      )
    : { isLoading: false };

  // Add sounds
  const { mutate: addSounds, isLoading: isAddingSounds } = useAddSoundsMutation({
    onSuccess: (data) => {
      data.insert_sounds?.returning.forEach((snd, index) => {
        // If the user is updating the sounds of a matn section
        // That already has sounds, the index of the new sounds
        // will not map correctly to indexes of sound form
        // So here we should subtract the length of the sound form from the
        // length of the new sounds to get the correct index
        const newIndex = soundForm.watch().sounds.length - newSounds.length;
        uploadSoundFile({
          data: serialize(soundForm.watch().sounds[newIndex].fileUrl, {}, undefined, 'file'),
          id: snd.soundId,
        });
      });
    },
  });

  // Upload sound file by id
  const { mutate: uploadSoundFile, isLoading: isUploadingSound } = useMultiPostApi({
    url: API_URLS.UPLOAD_SOUND,
  });

  // Delete sound file
  const { mutate: deleteSound, isLoading: isDeletingSound } = useDeleteSoundMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['SoundsByMatnSectionId'] });
      confirmDeleteSound.onFalse();
    },
  });
  // #endregion Services

  // #region handlers
  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);

  const handleDropSingleFile = useCallback((acceptedFiles: File[], index: number) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      soundForm.setValue(
        `sounds.${index}.fileUrl`,
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
    }
  }, []);

  // Sound handlers
  const handleAddNewSound = () => {
    soundForm.setValue('sounds', [...soundForm.getValues('sounds'), soundsDefaultValues]);
  };

  const handleDeleteSound = (soundIndex: number, soundId: string) => {
    soundId && confirmDeleteSound.onTrue();
    soundId && setSoundDeleteId(soundId!);
    !soundId &&
      soundForm.setValue(
        'sounds',
        soundForm.getValues('sounds').filter((_, index) => index !== soundIndex)
      );
  };

  // Words definitions handlers
  const handleAddNewWordDiff = () => {
    methods.setValue('wordsDefinitions', [
      ...methods.getValues('wordsDefinitions')!,
      defaultValues.wordsDefinitions!?.[0],
    ]);
  };

  const handleDeleteWordDiff = (wordIndex: number, translationId: string) => {
    translationId && confirmDeleteWord.onTrue();
    translationId && setTransDeleteId(translationId!);
    !translationId &&
      methods.setValue(
        'wordsDefinitions',
        methods.getValues('wordsDefinitions')!.filter((_, index) => index !== wordIndex)
      );
  };
  // #endregion handlers

  // #region useEffect
  useEffect(() => {
    // General form
    methods.setValue(`name.${currentTabValue}`, methods.watch().name[currentTabValue] || '');
    methods.setValue(
      `description.${currentTabValue}`,
      methods.watch().description[currentTabValue] || ''
    );
  }, [methods, currentTabValue]);

  useEffect(() => {
    // Sound form
    for (let i = 0; i < soundForm.watch().sounds?.length; i++) {
      for (let j = 0; j < allLangs.length; j++) {
        soundForm.setValue(
          `sounds.${i}.name.${allLangs[j].value}`,
          soundForm.watch().sounds[i].name!?.[allLangs[j].value] || ''
        );
        soundForm.setValue(
          `sounds.${i}.description.${allLangs[j].value}`,
          soundForm.watch().sounds[i].description!?.[allLangs[j].value] || ''
        );
      }
    }
  }, [JSON.stringify(soundForm.watch()), currentTabValue]);

  useEffect(() => {
    if (hasNewWordsDefs && (hasAddedSection || hasBeenEdited)) {
      addWordsDefinitions({
        translations: newWordsDefs!
          ?.filter((word) => isFilledWord({ word: word.word }))
          .map((e) => ({ ...e, mutoonSectionId: matnSectionId })),
      });
    }
    if (hasNewSounds && (hasAddedSection || hasBeenEdited)) {
      addSounds({
        sounds: newSounds
          .filter((sound) => isFilledSound({ name: sound.name }))
          .map((sound) => {
            return { ...sound, mutoonSectionId: matnSectionId, fileUrl: '' };
          })!,
      });
    }
  }, [hasAddedSection, hasBeenEdited]);
  // #endregion useEffect

  const memoizedValues = useMemo(() => {
    return {
      // Translations
      currentTabValue,
      handleChangeTab,
      allLangs,
      langsWithErrors,
      t,
      currentLang,
      // Main form (details & definitions)
      methods,
      onSubmit,
      handleSubmit,
      isUpdatingSec,
      isAddingMatnSection,
      mutateSectionDetails,
      isEditing,
      // Sounds form
      soundForm,
      soundsErrors,
      handleDropSingleFile,
      // Words definitions
      addDiffWords,
      isAddingWords,
      isLoadingWordsDefs,
      deleteWordDefinition,
      isDeletingWord,
      handleAddNewWordDiff,
      handleDeleteWordDiff,
      confirmDeleteWord,
      // Sound variables
      soundDeleteId,
      addSecSounds,
      isLoadingSounds,
      isUploadingSound,
      deleteSound,
      isDeletingSound,
      handleAddNewSound,
      handleDeleteSound,
      confirmDeleteSound,
      // Breadcurmbs names
      matnName,
      matnSectionName,
      // Others
      enqueueSnackbar,
      isLoadingSectionDetails,
      transDeleteId,
      confirm,
    };
  }, [
    // Translations
    currentTabValue,
    handleChangeTab,
    allLangs,
    langsWithErrors,
    t,
    currentLang,
    // Main form (details & definitions)
    methods,
    onSubmit,
    handleSubmit,
    isUpdatingSec,
    isAddingMatnSection,
    mutateSectionDetails,
    isEditing,
    // Sounds form
    soundForm,
    soundsErrors,
    handleDropSingleFile,
    // Words definitions
    addDiffWords,
    isAddingWords,
    isLoadingWordsDefs,
    deleteWordDefinition,
    isDeletingWord,
    handleAddNewWordDiff,
    handleDeleteWordDiff,
    confirmDeleteWord,
    // Sound variables
    soundDeleteId,
    addSecSounds,
    isLoadingSounds,
    isUploadingSound,
    deleteSound,
    isDeletingSound,
    handleAddNewSound,
    handleDeleteSound,
    confirmDeleteSound,
    // Others
    enqueueSnackbar,
    isLoadingSectionDetails,
    setTransDeleteId,
    confirm,
  ]);

  return memoizedValues;
};

export default useAddNewMatnSection;
