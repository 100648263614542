import { useLocales } from 'src/locales';
import * as Yup from 'yup';
import { enUS, esES, arSA } from '@mui/material/locale';
import { useMemo } from 'react';
import { ar, en } from 'yup-locales';
import moment from 'moment';
import { minutesSinceMidnight } from 'src/utils/minutes-calc-func';

export interface TranslationObjectSchema {
  [key: string]: any;
}

export interface Locale {
  label: string;
  value: string;
  systemValue: typeof enUS;
  icon: string;
  regex?: RegExp;
}

const useSchemas = () => {
  const { t, allLangs, currentViewLang } = useLocales();
  const isArabic = currentViewLang.value === 'ar';
  // #region Override custom validation messages
  // Mixed
  ar.mixed?.required && (ar.mixed.required = 'هذا الحقل مطلوب');
  en.mixed?.required && (en.mixed.required = 'This field is required');

  // Arabic
  ar.array?.min && (ar.array.min = 'يجب أن يكون للحقل ${min} عناصر على الأقل');
  // #endregion

  // Set library locale
  Yup.setLocale(isArabic ? ar : en);

  const translationObjectSchema = (requiredLangs?: Locale[], required: boolean = true) =>
    (requiredLangs || allLangs).reduce((schema: TranslationObjectSchema, lang: Locale) => {
      const requiredMessage = `${lang.label} ${t('translationTypes.isRequired')}`;
      const mismatchMessage = `${lang.label}: ${t('translationTypes.doesNotMatch')}`;

      return {
        ...schema,
        [lang.value]: required
          ? // If the lang is required, test it to match lang regex
            Yup.string().required(requiredMessage)
          : // If not required, validate only if the field is filled
            // Yup.string().test(
            //   'optionalLangValidation',
            //   mismatchMessage,
            //   (value) => !value || lang.regex!.test(value) // Pass validation if empty or matches regex
            // ),
            true,
      };
    }, {});

  // Add - edit category schema
  const categorySchema = (requiredLangs: Locale[]) =>
    Yup.object().shape({
      name: Yup.object().shape(translationObjectSchema(requiredLangs)).required(),
      description: Yup.object().shape(translationObjectSchema(requiredLangs, false)),
      categoryId: Yup.string(),
      parentId: Yup.string().nullable(),
    });

  // Add - edit level schema
  const levelSchema = Yup.object().shape({
    name: Yup.object().shape(translationObjectSchema()).required(),
    description: Yup.object().shape(translationObjectSchema(allLangs, false)),
    categoryId: Yup.string(),
  });

  // Add - edit matn schema
  const matnSchema = (requiredLangs: Locale[]) =>
    Yup.object().shape({
      name: Yup.object().shape(translationObjectSchema(requiredLangs)).required(),
      description: Yup.object().shape(translationObjectSchema(requiredLangs)).required(),
      matnId: Yup.string(),
      numberOfHadith: Yup.number().required(),
      referenceBookBookId: Yup.string().required(),
    });

  // Add - edit matn schema
  const generalMatnSchema = (requiredLangs: Locale[]) =>
    Yup.object().shape({
      name: Yup.object().shape(translationObjectSchema(requiredLangs)).required(),
      description: Yup.object().shape(translationObjectSchema(requiredLangs)),
      matnId: Yup.string(),
      referenceBookBookId: Yup.string(),
      isGeneral: Yup.boolean().required(),
    });

  // Add - edit book schema
  const bookSchema = Yup.object().shape({
    bookName: Yup.object().shape(translationObjectSchema()).required(),
    title: Yup.object().shape(translationObjectSchema()).required(),
    bookAuthor: Yup.object().shape(translationObjectSchema()).required(),
    bookLanguage: Yup.object().shape(translationObjectSchema()).required(),
    fromSchool: Yup.object().shape(translationObjectSchema()).required(),
    nazemName: Yup.object().shape(translationObjectSchema()).required(),
    dateOfBorn: Yup.string().required(),
    dateOfDeath: Yup.string().nullable(),
    numberOfLines: Yup.number().required(),
    pdfUrl: Yup.string().required(),
  });

  // Add - edit matn section schema
  const matnSectionSchema = Yup.object().shape({
    name: Yup.object().shape(translationObjectSchema()).required(),
    description: Yup.object().shape(translationObjectSchema()).required(),
    mutoonMatnId: Yup.string(),
  });

  // Edit difficult word schema
  const diffWordEditSchema = Yup.object().shape({
    word: Yup.object().shape(translationObjectSchema()).required(),
    translatedText: Yup.object().shape(translationObjectSchema()).required(),
    translationId: Yup.string(),
  });

  // Edit sound schema
  const soundEditSchema = Yup.object().shape({
    name: Yup.object().shape(translationObjectSchema()).required(),
    description: Yup.object().shape(translationObjectSchema()).required(),
    soundId: Yup.string().required(),
    fileUrl: Yup.string(),
  });

  // Add - edit session schema
  const sessionSchema = Yup.object().shape({
    title: Yup.object().shape(translationObjectSchema()).required(),
    description: Yup.object().shape(translationObjectSchema()).required(),
    startDate: Yup.string()
      .required()
      .test(
        'is-after-now',
        isArabic ? 'هذا التاريخ قد مر بالفعل' : 'This date has been already passed',
        function (value) {
          const now = new Date();
          const startDate = new Date(value);
          return startDate > now;
        }
      ),
    expiryDate: Yup.string()
      .required()
      .when('startDate', ([startDate], schema) => {
        return schema.test(
          'is-greater-than-startDate',
          isArabic
            ? 'تاريخ النهاية يجب أن يكون بعد تاريخ البداية'
            : 'Expiry date must be after the start date',
          function (value) {
            const startDateObj = new Date(startDate);
            const expiryDateObj = new Date(value);
            return expiryDateObj > startDateObj;
          }
        );
      }),
    mutoonMatnId: Yup.string().required(),
    mutoonMatnName: Yup.string().required(),
    prerequisiteIds: Yup.array().of(Yup.string().required()).required(),
    days: Yup.array().of(Yup.string().required()).required(),
    enable_conversation: Yup.boolean().required(),
    enable_raise_hand: Yup.boolean().required(),
    enable_recording: Yup.boolean().required(),
    enable_upload_files: Yup.boolean().required(),
    enable_video: Yup.boolean().required(),
    max_user_count: Yup.number().required(),
    frequency: Yup.string(),
    duration: Yup.number().required(),
  });

  // Add - edit hearing council schema
  const hearingCouncilSchema = (requiredLangs: Locale[]) =>
    Yup.object().shape({
      description: Yup.object().shape(translationObjectSchema(requiredLangs)).required(),
      sheikhName: Yup.string().required(),
      sessionName: Yup.object().shape(translationObjectSchema(requiredLangs)).required(),
      startDate: Yup.string()
        .required()
        .test(
          'is-after-now',
          isArabic ? 'هذا التاريخ قد مر بالفعل' : 'This date has been already passed',
          function (value) {
            const now = new Date();
            const startDate = new Date(value);
            return startDate > now;
          }
        )
        .test(
          'is-valid-day',
          isArabic
            ? 'تاريخ البداية يجب أن يتوافق مع الأيام المختارة'
            : 'Start date must match the selected days',
          function (value) {
            const { days } = this.parent;
            const startDay = moment(value).format('ddd'); // Get day of the week (e.g., 'Mon', 'Wed', 'Sat')
            return days.includes(startDay);
          }
        ),

      expiryDate: Yup.string()
        .required()
        .test(
          'is-greater-than-startDate',
          isArabic
            ? 'تاريخ النهاية يجب أن يكون بعد تاريخ البداية'
            : 'Expiry date must be after the start date',
          function (value) {
            const { startDate } = this.parent; // Access `startDate` from the current context
            const startDateObj = new Date(startDate);
            const expiryDateObj = new Date(value);
            return expiryDateObj > startDateObj;
          }
        )
        .test(
          'is-valid-day',
          isArabic
            ? 'تاريخ النهاية يجب أن يتوافق مع الأيام المختارة'
            : 'Expiry date must match the selected days',
          function (value) {
            const { days } = this.parent;
            const expiryDate = moment(value).format('ddd'); // Get day of the week (e.g., 'Mon', 'Wed', 'Sat')
            return days.includes(expiryDate);
          }
        )
        .test(
          'is-valid-time',
          t('dialogs.addNewHearingCouncil.errorMessages.expiryTime'),
          (value, context) => {
            const {
              parent: { startDate },
            } = context;
            const startTime = minutesSinceMidnight(moment(startDate).toDate());
            const endTime = minutesSinceMidnight(moment(value).toDate());
            return endTime > startTime;
          }
        ),

      days: Yup.array()
        .of(Yup.string().required())
        .test(
          'is-valid-duration',
          isArabic
            ? 'المدة بين تاريخ البداية والنهاية غير صالحة'
            : 'The duration between start and expiry date is invalid',
          function (value: string[] | undefined) {
            const { expiryDate, startDate } = this.parent;

            const daysNumbers = value?.map((day) => {
              return moment().day(day).day();
            });

            // This is the gap between the start weekDay and expiry dates
            const daysGapBetweenStartToEndWeekdays =
              daysNumbers?.length === 1 ? 0 : Math.max(...daysNumbers!) - Math.min(...daysNumbers!);

            const daysGapBetweenEndToStartWeekdays = Math.abs(7 - daysGapBetweenStartToEndWeekdays);

            // Calculate the gap between the start and expiry dates
            const startDateObj = moment(startDate);
            const expiryDateObj = moment(expiryDate);
            const durationInDays = expiryDateObj.diff(startDateObj, 'days') % 7; // Difference in days

            return (
              durationInDays === daysGapBetweenStartToEndWeekdays || // If the gap is equal to the first gap value
              durationInDays === daysGapBetweenEndToStartWeekdays // If the gap is equal to the second gap value
            );
          }
        ),

      price: Yup.number().required(),
      appointmentLimit: Yup.number()
        .required()
        .test(
          'appointment-limit-must-be-less-than-duration',
          t('dialogs.addNewHearingCouncil.errorMessages.appointmentLimit.lessThanCouncilTime'),
          (value, context) => {
            const {
              parent: { startDate, expiryDate },
            } = context;
            const startTime = minutesSinceMidnight(moment(startDate).toDate());
            const endTime = minutesSinceMidnight(moment(expiryDate).toDate());
            console.log(startTime, endTime);
            return value < endTime - startTime;
          }
        ),
      startTime: Yup.number(),
      endTime: Yup.number(),
      // enable_conversation: Yup.boolean().required(),
      // enable_raise_hand: Yup.boolean().required(),
      // enable_recording: Yup.boolean().required(),
      // enable_upload_files: Yup.boolean().required(),
      // enable_video: Yup.boolean().required(),
      // max_user_count: Yup.number().nullable(),
      duration: Yup.number().required(),
      autoCertificate: Yup.boolean(),
      certificateIds: Yup.array().of(Yup.string().required()).required().min(1),
      mutoon: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(),
          label: Yup.string().required(),
        })
      ),
      mutoonIds: Yup.array()
        .of(Yup.string().required())
        .test(
          'at-least-one-mutoonIds-or-generalMutoonIds',
          isArabic
            ? 'يجب عليك اختيار متن واحد على الأقل'
            : 'You should select at least one mutoon or general mutoon',
          function (value, context) {
            const {
              parent: { mutoonIds = [], generalMutoonIds = [] },
            } = context;
            return mutoonIds.length > 0 || generalMutoonIds.length > 0;
          }
        ),
      generalMutoon: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(),
          label: Yup.string().required(),
        })
      ),
      generalMutoonIds: Yup.array().of(Yup.string().required()),
    });

  // Add role schema
  const AddRoleSchema = Yup.object().shape({
    name: Yup.string().required(),
  });

  // Reject user record schema
  const RejectRecordSchema = Yup.object().shape({
    adminMessage: Yup.string(),
    recordingId: Yup.string().required(),
  });

  // Reject teacher request schema
  const RejectTeacherRequest = Yup.object().shape({
    adminMsg: Yup.string().nullable(),
    status: Yup.string().nullable(),
    authorizedUserId: Yup.string().required(),
  });

  // Add admin schema
  const AddAdminSchema = (isEditing: boolean) =>
    Yup.object().shape({
      email: Yup.string().email().required(),
      username: Yup.string().required(),
      roleId: Yup.string().required(),
      password: isEditing ? Yup.string() : Yup.string().required().min(8),
      confirmPassword: isEditing
        ? Yup.string()
        : Yup.string()
            .required()
            .min(8)
            .test(
              'passwords-match',
              isArabic
                ? 'يجب أن يكون هذا الحقل مطابقاً لكلمة المرور'
                : 'This field must match the password',
              function (value) {
                return value === this.parent.password;
              }
            ),
    });

  // Add exam schema
  const AddExamSchema = Yup.object().shape({
    certificateexamId: Yup.string(),
    description: Yup.object().shape(translationObjectSchema()).required(),
    duration: Yup.number(),
    gradeA: Yup.number(),
    gradeB: Yup.number(),
    gradeC: Yup.number(),
    gradeD: Yup.number(),
    gradeF: Yup.number(),
    minimumScore: Yup.number(),
    limit: Yup.number(),
    maxAttempts: Yup.number(),
    mutonId: Yup.string().required(),
    mutonName: Yup.string().required(),
    status: Yup.string(),
    title: Yup.object().shape(translationObjectSchema()).required(),
  });

  // Add questions schema
  const AddQuestionsSchema = Yup.object().shape({
    questions: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string().required(),
          isQuiz: Yup.boolean(),
          correctAnswerText: Yup.object().when('type', {
            is: 'written',
            then: () => Yup.object().shape(translationObjectSchema()).required(),
          }),
          explanation: Yup.object().shape(translationObjectSchema(allLangs, false)),
          hint: Yup.object().shape(translationObjectSchema(allLangs, false)),
          mutoonSectionId: Yup.string().nullable(),
          points: Yup.number().required(),
          questionText: Yup.object().shape(translationObjectSchema()).required(),
          questionId: Yup.string(),
          examId: Yup.string(),
          correctAnswerIndex: Yup.number().when('type', {
            is: 'choice',
            then: () => Yup.number().required(),
            otherwise: () => Yup.number().nullable(),
          }),
          choicesType: Yup.string().when('type', {
            is: 'choice',
            then: () => Yup.string().required(),
            otherwise: () => Yup.string().nullable(),
          }),
          choicesOptions: Yup.array().when('type', {
            is: 'choice',
            then: () => Yup.array().of(Yup.string().required()).min(1).required(),
          }),
        })
      )
      .required(),
    matnId: Yup.string(),
    matnName: Yup.string(),
    pageValue: Yup.number(),
  });

  // Knowledge base schema
  const AddKnowledgeBaseSchema = Yup.object().shape({
    title: Yup.object().shape(translationObjectSchema()).required(),
    content: Yup.object().shape(translationObjectSchema()).required(),
    cover_url: Yup.string().required(),
  });

  const AddFaqSchema = Yup.object().shape({
    question: Yup.object().shape(translationObjectSchema()).required(),
    answer: Yup.object().shape(translationObjectSchema()).required(),
  });

  const AddCustomBadgeSchema = Yup.object().shape({
    programName: Yup.object().shape(translationObjectSchema()).required(),
    badgeImageURL: Yup.string().required(),
    badgeType: Yup.string().oneOf(['category', 'matn', 'hearingCouncil']).required(),
    categoryId: Yup.string().nullable(),
    mutoonId: Yup.string().nullable(),
    hearingCouncilId: Yup.string().nullable(),
    categoryName: Yup.string(),
    mutoonName: Yup.string(),
    hearingCouncilName: Yup.string(),
  });

  const AddGeneralBadgeSchema = Yup.object().shape({
    programName: Yup.object().shape(translationObjectSchema()).required(),
    badgeImageURL: Yup.string().required(),
    generalType: Yup.string().required(),
    count: Yup.number().required(),
    categoryId: Yup.string().nullable(),
    mutoonId: Yup.string().nullable(),
    hearingCouncilId: Yup.string().nullable(),
  });

  const ReplyToMessageSchema = Yup.object().shape({
    response: Yup.string().required(),
    contactId: Yup.string().required(),
  });

  const ChangeTeacherRequestSchema = Yup.object().shape({
    status: Yup.string().required(),
    categoriesIds: Yup.array().when('status', {
      is: 'approved',
      then: () =>
        Yup.array()
          .of(
            Yup.object().shape({
              label: Yup.string().required(),
              value: Yup.string().required(),
            })
          )
          .min(1)
          .required(),
      otherwise: () => Yup.array().nullable(),
    }),
  });

  const ChangeSchoolRequestSchema = Yup.object().shape({
    status: Yup.string().required(),
  });

  return {
    categorySchema,
    levelSchema,
    matnSchema,
    bookSchema,
    matnSectionSchema,
    diffWordEditSchema,
    soundEditSchema,
    sessionSchema,
    hearingCouncilSchema,
    AddRoleSchema,
    RejectRecordSchema,
    AddAdminSchema,
    AddExamSchema,
    AddQuestionsSchema,
    AddKnowledgeBaseSchema,
    AddFaqSchema,
    ReplyToMessageSchema,
    AddCustomBadgeSchema,
    AddGeneralBadgeSchema,
    RejectTeacherRequest,
    ChangeTeacherRequestSchema,
    ChangeSchoolRequestSchema,
    generalMatnSchema,
  };
};

export default useSchemas;
